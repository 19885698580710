import React, { useEffect } from 'react';

const ParticlesCanvas = () => {
  useEffect(() => {
    const canvas = document.getElementById('particles-canvas');
    const ctx = canvas.getContext('2d');

    // Calculate the height to cover both Header and WhyUs
    const headerElement = document.getElementById('header');
    const whyUsElement = document.getElementById('why-us');

    const headerHeight = headerElement?.offsetHeight || window.innerHeight;
    const whyUsHeight = whyUsElement?.offsetHeight || window.innerHeight;

    canvas.width = window.innerWidth;
    canvas.height = headerHeight + whyUsHeight;

    const particlesArray = [];
    const logoColor = 'rgb(228, 234, 236)';

    class PolygonParticle {
      constructor(x, y, size, sides, color, speedX, speedY) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.sides = sides;
        this.color = color;
        this.speedX = speedX;
        this.speedY = speedY;
      }

      draw() {
        ctx.beginPath();
        const angle = (Math.PI * 2) / this.sides;
        for (let i = 0; i < this.sides; i++) {
          const xOffset = this.x + this.size * Math.cos(angle * i);
          const yOffset = this.y + this.size * Math.sin(angle * i);
          if (i === 0) {
            ctx.moveTo(xOffset, yOffset);
          } else {
            ctx.lineTo(xOffset, yOffset);
          }
        }
        ctx.closePath();
        ctx.fillStyle = this.color;
        ctx.fill();
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.x > canvas.width || this.x < 0) this.speedX = -this.speedX;
        if (this.y > canvas.height || this.y < 0) this.speedY = -this.speedY;
        this.draw();
      }
    }

    function initParticles() {
      const numberOfParticles = Math.floor(Math.random() * 7) + 8;
      for (let i = 0; i < numberOfParticles; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const size = Math.random() * 60 + 40;
        const sides = Math.floor(Math.random() * 4) + 6;
        const speedX = Math.random() * 1 - 0.5;
        const speedY = Math.random() * 3 - 0.5;
        particlesArray.push(new PolygonParticle(x, y, size, sides, logoColor, speedX, speedY));
      }
    }

    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particlesArray.forEach((particle) => particle.update());
      requestAnimationFrame(animateParticles);
    }

    initParticles();
    animateParticles();

    window.addEventListener('resize', () => {
      const headerHeight = headerElement?.offsetHeight || window.innerHeight;
      const whyUsHeight = whyUsElement?.offsetHeight || window.innerHeight;

      canvas.width = window.innerWidth;
      canvas.height = headerHeight + whyUsHeight;

      particlesArray.length = 0;
      initParticles();
    });
  }, []);

  return (
    <canvas
      id="particles-canvas"
      className="absolute top-0 left-0 w-full z-20 pointer-events-none"
      style={{ position: 'absolute' }}
    ></canvas>
  );
};

export default ParticlesCanvas;
