import React, { useState, useEffect } from 'react';
import { FaPhoneAlt } from 'react-icons/fa'; // Importing call icon

const ContactHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}

      <div className="pt-16 md:pt-24">
        <header
          className="bg-white relative overflow-hidden flex flex-col md:flex-row items-center justify-between px-8 md:px-16 py-10 rounded-lg"
          style={{ minHeight: '500px' }}
        >
          {/* Left Side: Title and Tagline */}
          <div className="w-full md:w-1/2 flex flex-col justify-center">
            <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 leading-tight mb-6 animate-fade-in">
              Let's Talk About Your Project
            </h2>
            <p className="text-lg md:text-xl text-gray-600 italic mb-6 animate-fade-in">
              Feel free to ask any questions or let’s talk about our future collaboration.
            </p>
          </div>

          {/* Right Side: Call Icon */}
          <div className="w-full md:w-1/2 flex justify-center items-center relative">
            <div className="absolute w-72 h-72 bg-gradient-to-tr from-blue-100 to-purple-200 rounded-full blur-3xl z-0"></div>
            <FaPhoneAlt size={120} className="relative z-10 text-blue-500 animate-slide-in" />
          </div>
        </header>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes slideIn {
          from {
            opacity: 0;
            transform: translateX(-50px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        .animate-fade-in {
          animation: fadeIn 1s ease-out;
        }

        .animate-slide-in {
          animation: slideIn 1s ease-out;
        }
      `}</style>
    </>
  );
};

export default ContactHeader;
