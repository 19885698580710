import React from 'react';
import { FaBrain, FaHandshake, FaTools, FaRocket } from 'react-icons/fa'; // Import icons

const OurProcess = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">How Our Process Works</h2>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6">

          {/* Step 1: Consultation */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center">
            <FaBrain size={40} className="text-blue-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Consultation</h3>
            <p className="text-gray-600">
              We start by understanding your needs and requirements in a detailed consultation session.
            </p>
          </div>

          {/* Step 2: Planning */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center">
            <FaHandshake size={40} className="text-green-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Planning</h3>
            <p className="text-gray-600">
              Together, we plan and outline the project scope, timeline, and milestones.
            </p>
          </div>

          {/* Step 3: Execution */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center">
            <FaTools size={40} className="text-yellow-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Execution</h3>
            <p className="text-gray-600">
              Our team gets to work, bringing your vision to life with precision and skill.
            </p>
          </div>

          {/* Step 4: Launch */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center">
            <FaRocket size={40} className="text-red-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Launch</h3>
            <p className="text-gray-600">
              We ensure a smooth launch and continue to provide support and enhancements as needed.
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OurProcess;
