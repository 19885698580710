import React from 'react';
import { useNavigate } from 'react-router-dom';

const AboutComp = () => {
  const navigate = useNavigate();
  const handleAboutClick = () => {
    navigate('/About'); // Navigate to /About route
  };

  return (
    <div className="flex flex-col md:flex-row w-full h-auto md:h-100 bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Left side image box */}
      <div className="w-full md:w-1/3 bg-gray-100">
        {/* Replace with your image */}
        <img src="../../../asset/aboutus/aboutcomp.png" alt="About Us" className="w-full h-60 md:h-full object-cover" />
      </div>

      {/* Right side content */}
      <div className="w-full md:w-2/3 p-6 md:p-10">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-6 text-gray-800 font-serif">Who We Are?</h2>
        <p className="text-md md:text-lg mb-8 text-justify text-gray-600 leading-relaxed font-light">
          At <span className="font-semibold">CeylonSprint Pvt Ltd</span>, we're more than just a team – we're a powerhouse of tech innovators and connoisseurs, passionately committed to crafting cutting-edge software solutions. Our ambition is to catalyze business success and propel technological evolution. We proudly offer a diverse array of software packages, tailored to meet the dynamic needs of our clients.
          <br /><br />
          Beyond software development, we operate thriving online platforms for rental, buying, advertising, and e-learning facilities. Our expertise extends into the realm of IT consultation, where we provide a spectrum of services designed to foster growth and innovation. Join us in our journey to reshape the digital landscape, one innovative solution at a time.
        </p>
        <button 
          className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 px-6 rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 font-medium tracking-wide"
          onClick={handleAboutClick}
        >
          Learn More About Us
        </button>
      </div>
    </div>
  );
};

export default AboutComp;
