import React from 'react';
import Slider from 'react-slick'; // Importing react-slick
import 'slick-carousel/slick/slick.css'; // Importing slick-carousel styles
import 'slick-carousel/slick/slick-theme.css';

const products = [
  { name: 'Invoice Package', description: 'Streamlined invoicing and billing solutions.' },
  { name: 'HR – Payroll Package', description: 'Comprehensive HR and payroll management system.' },
  { name: 'Account Package', description: 'Robust accounting software for all your financial needs.' },
  { name: 'CRM', description: 'Customer Relationship Management to enhance customer engagement.' },
  { name: 'Inventory Management System', description: 'Efficient inventory tracking and management tools.' }
];

const Product = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-gradient-to-br bg-white py-12 px-4 md:px-8 rounded-lg shadow-md">
      <h2 className="text-4xl font-extrabold text-center mb-10 text-gray-800 uppercase">Innovative Product Suite</h2>
      <Slider {...settings}>
        {products.map((product, index) => (
          <div key={index} className="p-4">
            <div className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 flex flex-col items-center">
              <div className="w-20 h-20 mb-4 bg-blue-100 rounded-full flex items-center justify-center shadow-inner">
                <span className="text-3xl font-bold text-blue-600">{product.name[0]}</span>
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-800 text-center">{product.name}</h3>
              <p className="text-sm text-gray-600 text-center">{product.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Product;
