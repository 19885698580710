import React from 'react';

const services = [
  { title: 'UI & UX Design', description: 'Crafting visually stunning and highly intuitive user experiences.', image: '../../../asset/services/1.png' },
  { title: 'AI Solutions', description: 'Innovative artificial intelligence solutions to drive business growth.', image: '../../../asset/services/2.png' },
  { title: 'Static Web Development', description: 'Robust and reliable static websites for various purposes.', image: '../../../asset/services/3.png' },
  { title: 'Hosting', description: 'High-performance hosting solutions for businesses of all sizes.', image: '../../../asset/services/4.png' },
  { title: 'Dynamic Web Development', description: 'Interactive and dynamic web applications tailored to your needs.', image: '../../../asset/services/5.png' },
  { title: 'ERP Development', description: 'Efficient and integrated ERP systems for streamlined business processes.', image: '../../../asset/services/6.png' },
  { title: 'Mobile App Development', description: 'Cutting-edge mobile applications to enhance user engagement.', image: '../../../asset/services/7.png' },
  { title: 'Cross-Platform Solutions', description: 'Flexible cross-platform development for maximum reach and impact.', image: '../../../asset/services/8.png' },
  { title: 'HR and Payroll Solution', description: 'Comprehensive HR and payroll systems for efficient workforce management.', image: '../../../asset/services/9.png' }
];

const Services = () => {
  return (
    <>
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .animate-fade-in-up {
            animation: fadeInUp 0.6s ease-out;
          }

          .animate-fade-in {
            animation: fadeIn 0.6s ease-out;
          }
        `}
      </style>
      <div className="bg-white p-6 md:p-12 shadow-lg rounded-lg">
        <h2 className="text-4xl font-extrabold text-center mb-10 text-gray-800 font-sans uppercase">Service Spectrum</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {services.map((service, index) => (
            <div 
              key={index} 
              className="bg-gradient-to-r from-blue-50 via-white to-blue-50 border border-gray-300 rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center transform hover:-translate-y-2 hover:scale-105">
              <div className="w-20 h-20 mb-4 flex items-center justify-center bg-gradient-to-br from-blue-200 to-blue-400 rounded-full shadow-inner">
                <img
                  src={service.image}
                  alt={service.title}
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-10 h-10 object-contain"
                />
              </div>
              <h3 className="font-bold text-lg text-gray-900 mb-2 animate-fade-in-up uppercase tracking-wider">{service.title}</h3>
              <p className="text-sm text-gray-700 animate-fade-in leading-relaxed">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Services;
