import React from 'react';

const WhyUs = () => {
  const features = [
    { title: 'INNOVATIVE SOLUTIONS', icon: '🚀' },
    { title: 'EXPERT TEAM', icon: '👩‍💼' },
    { title: 'CUSTOMER-CENTRIC APPROACH', icon: '💡' },
    { title: 'PROVEN TRACK RECORD', icon: '🏆' },
    { title: 'ADVANCED TECHNOLOGY', icon: '🧑‍💻' },
    { title: 'GLOBAL REACH, LOCAL INSIGHT', icon: '🌍' },
    { title: 'RELIABLE SUPPORT', icon: '🤝' },
    { title: 'CONTINUOUS IMPROVEMENT', icon: '⬆️' },
  ];

  return (
    <div
      id="why-us"
      className="bg-white py-8 flex flex-col items-center justify-center relative z-22"
      style={{ maxWidth: '95%', margin: '0 auto' }}
    >
      <h2 
        className="text-center text-4xl font-extrabold mb-6 z-30" 
        style={{ color: '#1D2D36', fontFamily: 'Montserrat, sans-serif' }}
      >
        WHY PARTNER WITH US
      </h2>
      <div
        className="feature-carousel overflow-hidden z-30"
        style={{ 
          whiteSpace: 'nowrap', 
          position: 'relative', 
          width: '90%', 
          border: 'none', 
          borderRadius: '10px',
          padding: '10px'
        }}
      >
        <div
          className="feature-container"
          style={{ 
            display: 'inline-block', 
            animation: 'scrollFeature 15s linear infinite', 
            border: 'none'
          }}
        >
          {features.concat(features).map((feature, index) => (
            <div
              key={index}
              className="feature-item flex flex-col items-center justify-center p-6 rounded-full transform hover:scale-105 transition-all duration-300 ease-in-out"
              style={{
                width: '175px',
                height: '175px',
                marginRight: '20px',
                display: 'inline-flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
              }}
            >
              <span className="icon text-4xl mb-4" style={{ color: '#1D2D36' }}>
                {feature.icon}
              </span>
              <span
                className="title text-sm font-bold text-center"
                style={{ color: '#1D2D36', whiteSpace: 'normal', textAlign: 'center' }}
              >
                {feature.title}
              </span>
            </div>
          ))}
        </div>
      </div>

      <style>{`
        @keyframes scrollFeature {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default WhyUs;
