import React from 'react';

const Minimal = () => {
  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-6">

        {/* Row Container */}
        <div className="flex flex-col md:flex-row justify-center items-center gap-8">

          {/* Mission Box */}
          <div className="bg-gradient-to-br from-blue-50 to-white p-8 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-xl">
            <h3 className="text-3xl font-bold text-gray-800 mb-4 text-center">Our Mission</h3>
            <p className="text-gray-700 text-justify leading-relaxed">
              At <span className="font-semibold text-blue-600">Ceylon Sprint</span>, our mission is to empower individuals, businesses, and organizations to leverage cutting-edge technology. By being catalysts for digital transformation, we deliver innovative solutions that redefine the possibilities of the digital experience.
            </p>
          </div>

          {/* Image Box */}
          {/* <div className="relative">
            <div className="absolute inset-0 w-72 h-72 bg-gradient-to-tr from-blue-100 to-purple-200 rounded-full blur-3xl -z-10"></div>
            <img
              src="../../../asset/about/1.png"
              alt="Visual Representation"
              className="rounded-lg shadow-lg w-96 object-cover"
            />
          </div> */}

          {/* Vision Box */}
          <div className="bg-gradient-to-br from-white to-blue-50 p-8 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-xl">
            <h3 className="text-3xl font-bold text-gray-800 mb-4 text-center">Our Vision</h3>
            <p className="text-gray-700 text-justify leading-relaxed">
              Our vision at <span className="font-semibold text-blue-600">Ceylon Sprint</span> is to set global benchmarks for innovation and quality in the digital space. We strive to ensure every client we serve achieves unparalleled success, becoming a beacon of digital excellence through our transformative solutions.
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Minimal;
