import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    return (
        <nav
            className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
                isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
            }`}
        >
            <div className="container mx-auto flex justify-between items-center py-4 px-6">
                {/* Logo */}
                {isScrolled && (
                    <div className="shrink-0">
                        <img
                            src="../../../asset/light-logo.png"
                            alt="Logo"
                            className="h-12"
                        />
                    </div>
                )}

                {!isScrolled && (
                                    <div className="shrink-0  bg-transparent">
                                        {/* <img
                                            src="../../../asset/light-logo.png"
                                            alt="Logo"
                                            className="h-8"
                                        /> */}
                                    </div>
                                )}

                {/* Navigation Items or Mobile Button Always on Right Side */}
                <div className="flex items-center space-x-4">
                    {/* Hamburger Menu for Mobile */}
                    {isMobile && (
                        <button
                            onClick={toggleMobileMenu}
                            className="text-gray-600 hover:text-blue-600 focus:outline-none md:hidden"
                        >
                            {isMobileMenuOpen ? <FaTimes size={28} /> : <FaBars size={28} />}
                        </button>
                    )}

                    {/* Render Desktop or Mobile Navigation */}
                    {!isMobile && <DesktopNav />}
                </div>
            </div>

            {/* Mobile Menu */}
            {isMobile && isMobileMenuOpen && (
                <div
                    className={`absolute top-16 right-0 w-full bg-white shadow-lg p-6 transform ${
                        isMobileMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
                    } transition-all duration-300 ease-in-out md:hidden`}
                >
                    <MobileNav toggleMenu={toggleMobileMenu} />
                </div>
            )}
        </nav>
    );
};

export default Navbar;
