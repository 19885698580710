import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaTools, FaInfoCircle, FaEnvelope } from 'react-icons/fa';

const MobileNav = ({ toggleMenu }) => {
    return (
        <div className="absolute top-16 left-0 w-full bg-gradient-to-r from-blue-50 to-blue-100 shadow-lg rounded-b-lg p-6 md:hidden">
            <ul className="flex flex-col space-y-4 text-gray-800 text-lg font-medium">
                <li className="flex items-center space-x-4">
                    <FaHome className="text-blue-600" />
                    <Link
                        to="/"
                        className="flex-grow hover:text-blue-800 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        Home
                    </Link>
                </li>
                <li className="flex items-center space-x-4">
                    <FaTools className="text-blue-600" />
                    <Link
                        to="/Services"
                        className="flex-grow hover:text-blue-800 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        Services
                    </Link>
                </li>
                <li className="flex items-center space-x-4">
                    <FaInfoCircle className="text-blue-600" />
                    <Link
                        to="/About"
                        className="flex-grow hover:text-blue-800 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        About Us
                    </Link>
                </li>
                <li className="flex items-center space-x-4">
                    <FaEnvelope className="text-blue-600" />
                    <Link
                        to="/Contact"
                        className="flex-grow hover:text-blue-800 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        Contact Us
                    </Link>
                </li>
            </ul>
            <style>{`
                @keyframes slideDown {
                    0% {
                        transform: translateY(-20px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                div {
                    animation: slideDown 0.5s ease-out;
                }
            `}</style>
        </div>
    );
};

export default MobileNav;
