import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../../store/actions/postsActions';

const ServicesList = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.posts.items);
  const loading = useSelector((state) => state.posts.loading);
  const error = useSelector((state) => state.posts.error);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  if (loading) 
    return <div className="flex items-center justify-center h-screen">Loading...</div>;

  if (error) 
    return <div className="flex items-center justify-center h-screen text-red-500 font-bold">Error: {error}</div>;

  return (
    <div className="services-list grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 p-8 bg-white">
      {services.map((service, index) => (
        <div
          key={index}
          className="service-item bg-white shadow-xl rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
        >
          <div className="relative">
            <img
              src={service.serviceImageUrl}
              alt={service.serviceTopic}
              className="w-full h-60 object-cover"
              onContextMenu={(e) => e.preventDefault()} // Disable right-click on image
            />
            <div className="absolute bottom-0 left-0 bg-gradient-to-t from-black to-transparent w-full p-4">
              <h3 className="text-white text-lg font-bold">{service.serviceTopic}</h3>
            </div>
          </div>
          <div className="p-4 bg-white">
            <ul className="text-sm text-gray-600 leading-relaxed space-y-2">
              {service.serviceDescription.map((description, descIndex) => (
                <li key={descIndex} className="flex items-start">
                  <span className="mr-2 text-blue-600">&#8226;</span>
                  {description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesList;
