import React from 'react';
import { FaUsers, FaIndustry, FaHandshake } from 'react-icons/fa'; // Import relevant icons
import '../../styles/OurNetwork.css';

const OurNetwork = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8 animate-fade-in-down">Our Professional Network</h2>
        
        {/* Network Representation with Animation */}
        <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6">

          {/* Our Clients with Fade-In-Left Animation */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center animate-fade-in-left">
            <FaUsers size={40} className="text-blue-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Our Clients</h3>
            <p className="text-gray-600">
              Building strong and lasting relationships, focusing on understanding and fulfilling unique needs.
            </p>
          </div>

          {/* Our Team with Zoom-In Animation */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center animate-zoom-in">
            <FaHandshake size={40} className="text-green-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Our Team</h3>
            <p className="text-gray-600">
              A dedicated team working collaboratively to innovate and deliver high-quality solutions.
            </p>
          </div>

          {/* Our Suppliers with Fade-In-Right Animation */}
          <div className="bg-white p-6 rounded shadow-lg w-72 text-center animate-fade-in-right">
            <FaIndustry size={40} className="text-yellow-500 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Our Suppliers</h3>
            <p className="text-gray-600">
              Strong partnerships with suppliers ensure access to the best resources and materials.
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OurNetwork;
