import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
  FaWhatsapp,
  FaPaintBrush,
  FaCode,
  FaServer,
  FaBrain,
  FaMobileAlt,
  FaCrosshairs,
  FaUserTie,
  FaEnvelope,
  FaInfoCircle,
} from 'react-icons/fa';

const Footer = () => {
  const whatsAppNumber = "+94707330432";
  const whatsAppLink = `https://wa.me/${whatsAppNumber}`;

  return (
    <footer className="bg-gray-900 text-gray-200 py-12">
      <div className="container mx-auto px-6 lg:px-20">
        {/* Footer Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-start">
          {/* Services Section */}
          <div>
            <h3 className="text-xl font-bold text-white mb-6">Our Services</h3>
            <ul className="space-y-4">
              {[
                { icon: FaPaintBrush, text: 'UI & UX Design' },
                { icon: FaCode, text: 'Static Web Development' },
                { icon: FaCode, text: 'Dynamic Web Development' },
                { icon: FaServer, text: 'Web Hosting' },
                { icon: FaBrain, text: 'AI Solutions' },
                { icon: FaUserTie, text: 'ERP Development' },
                { icon: FaMobileAlt, text: 'Mobile App Development' },
                { icon: FaCrosshairs, text: 'Cross-Platform Solutions' },
                { icon: FaUserTie, text: 'HR and Payroll Solution' },
              ].map((service, index) => (
                <li
                  key={index}
                  className="flex items-center space-x-3 hover:text-blue-400 transition-colors duration-300"
                >
                  <service.icon className="text-blue-500" />
                  <span>{service.text}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Quick Navigation */}
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold text-white mb-6">Quick Navigation</h3>
            <ul className="space-y-4">
              <li>
                <Link
                  to="/contact"
                  className="hover:text-blue-400 transition-colors duration-300 flex items-center space-x-2"
                >
                  <FaEnvelope />
                  <span>Contact Us</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="hover:text-blue-400 transition-colors duration-300 flex items-center space-x-2"
                >
                  <FaInfoCircle />
                  <span>About Us</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div className="text-center">
            <h3 className="text-xl font-bold text-white mb-6">Follow Us</h3>
            <div className="flex justify-center space-x-4">
              {[
                { link: 'https://www.facebook.com/CeylonSprint', icon: FaFacebook },
                { link: 'https://twitter.com/CeylonSprint', icon: FaTwitter },
                { link: 'https://www.instagram.com/CeylonSprint', icon: FaInstagram },
                { link: 'https://www.linkedin.com/company/ceylonsprint', icon: FaLinkedin },
                { link: 'https://www.tiktok.com/@CeylonSprint', icon: FaTiktok },
                { link: 'https://www.youtube.com/CeylonSprint', icon: FaYoutube },
                { link: whatsAppLink, icon: FaWhatsapp },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors duration-300"
                >
                  <social.icon size={24} />
                </a>
              ))}
            </div>
            <p className="mt-6 text-lg font-bold text-blue-400">Ceylon Sprint Pvt Ltd</p>
            <img
              src="../../../asset/light-logo.png"
              alt="Ceylon Sprint Pvt Ltd Logo"
              className="h-16 mx-auto mt-4"
            />
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="border-t border-gray-700 mt-12 pt-6 text-center">
          <p className="text-sm">&copy; {new Date().getFullYear()} Ceylon Sprint Pvt Ltd. All Rights Reserved.</p>
          <p className="mt-2">
            <Link to="/privacy" className="hover:text-blue-400 transition-colors duration-300">
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link to="/terms" className="hover:text-blue-400 transition-colors duration-300">
              Terms of Use
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
