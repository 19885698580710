import React from 'react';
import { Link } from 'react-router-dom';

const DesktopNav = () => {
    return (
        <ul className="flex items-center space-x-8 text-lg font-medium text-black">
            <li>
                <Link
                    to="/"
                    className="relative hover:text-blue-600 transition-colors duration-300 after:content-[''] after:block after:h-0.5 after:bg-blue-600 after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300 after:ease-in-out"
                >
                    Home
                </Link>
            </li>
            <li>
                <Link
                    to="/Services"
                    className="relative hover:text-blue-600 transition-colors duration-300 after:content-[''] after:block after:h-0.5 after:bg-blue-600 after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300 after:ease-in-out"
                >
                    Services
                </Link>
            </li>
            <li>
                <Link
                    to="/About"
                    className="relative hover:text-blue-600 transition-colors duration-300 after:content-[''] after:block after:h-0.5 after:bg-blue-600 after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300 after:ease-in-out"
                >
                    About Us
                </Link>
            </li>
            <li>
                <Link
                    to="/Contact"
                    className="relative hover:text-blue-600 transition-colors duration-300 after:content-[''] after:block after:h-0.5 after:bg-blue-600 after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300 after:ease-in-out"
                >
                    Contact Us
                </Link>
            </li>
        </ul>
    );
};

export default DesktopNav;
