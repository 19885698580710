import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { submitContactForm } from '../../store/actions/contactActions';
import { FaPhone, FaEnvelope, FaBuilding, FaCommentAlt, FaWhatsapp } from 'react-icons/fa';

const GetIn = () => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const whatsAppNumber = "+94707330432";
  const companyLogoURL = "https://yourwebsite.com/path-to-your-logo.jpg";
  const message = `Hello, I'm interested in learning more about your services. Here's our logo: ${companyLogoURL}`;
  const urlEncodedMessage = encodeURIComponent(message);
  const whatsAppLink = `https://wa.me/${whatsAppNumber}?text=${urlEncodedMessage}`;

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organization: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      organization: Yup.string(),
      message: Yup.string().required('Message is required')
    }),
    onSubmit: values => {
      dispatch(submitContactForm(values))
        .then(() => {
          setIsSubmitted(true);
          formik.resetForm();
        })
        .catch(error => console.error('Submission Error:', error));
    }
  });

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4 md:flex items-center justify-between gap-8">
        {/* Left Side: Contact Form */}
        <div className="md:w-1/2 bg-white p-6 rounded-lg shadow-xl">
          <h2 className="text-xl font-bold mb-4 text-gray-800 text-center">Get in Touch</h2>
          {isSubmitted && <div className="text-green-500 mb-4 text-center">Thank you for contacting us. We will get back to you soon.</div>}
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="Your Name"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {formik.touched.name && formik.errors.name && <div className="text-red-500 text-xs">{formik.errors.name}</div>}

              <input
                type="text"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="Your Phone Number"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {formik.touched.phone && formik.errors.phone && <div className="text-red-500 text-xs">{formik.errors.phone}</div>}

              <input
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Your Email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {formik.touched.email && formik.errors.email && <div className="text-red-500 text-xs">{formik.errors.email}</div>}

              <input
                type="text"
                name="organization"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.organization}
                placeholder="Your Organization"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              {formik.touched.organization && formik.errors.organization && <div className="text-red-500 text-xs">{formik.errors.organization}</div>}

              <textarea
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                placeholder="Your Message"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                rows="4"
              ></textarea>
              {formik.touched.message && formik.errors.message && <div className="text-red-500 text-xs">{formik.errors.message}</div>}

              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-all duration-300 font-semibold"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        {/* Right Side: Contact Information */}
        <div className="md:w-1/2 bg-white p-6 rounded-lg shadow-xl relative">
          {/* Hotline Display */}
          <div
  className="absolute top-0 right-0 bg-blue-500 text-white py-2 px-3 rounded-bl-lg text-base font-semibold md:py-4 md:px-6 md:text-lg leading-tight md:leading-normal"
>
  <span className="block md:hidden">Hotline: +94 70 733 0 433</span> {/* For mobile view */}
  <span className="hidden md:block">Hotline: +94 70 733 0 433</span> {/* For desktop view */}
</div>
          <h2 className="text-xl font-bold mb-6 text-gray-800 text-center">Contact Information</h2>
          <div className="space-y-4 text-gray-700">
            <div className="flex items-center space-x-4">
              <FaEnvelope className="text-blue-500" />
              <p>Email: info@ceylonsprint.com</p>
            </div>
            <div className="flex items-center space-x-4">
              <FaBuilding className="text-blue-500" />
              <p>Finance Dept: finance@ceylonsprint.com</p>
            </div>
            <div className="flex items-center space-x-4">
              <FaCommentAlt className="text-blue-500" />
              <p>Support: support@ceylonsprint.com</p>
            </div>
            <a href={whatsAppLink} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-4">
              <FaWhatsapp className="text-green-500" />
              <p>WhatsApp: +94 70 733 0 432</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetIn;
