import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import LogoAnimation from '../../LogoAnimation';

const Header = () => {
  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const taglineVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 0.5, scale: 1, transition: { duration: 1.5 } },
  };

  const descriptions = [
    "Empowering businesses with robust solutions. Transforming ideas into reality.",
    "From the heart of Ceylon to the global stage, our software services are recognized for their precision and excellence.",
    "Empowering businesses with future-ready software platforms that drive growth."
  ];

  const [currentDescription, setCurrentDescription] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDescription((prev) => (prev + 1) % descriptions.length);
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(interval);
  }, [descriptions.length]);

  return (
    <>
      <LogoAnimation />

      <header
        id="header"
        className="relative pt-16 bg-transparent h-screen flex flex-col items-center justify-center overflow-hidden"
        style={{ position: 'relative', zIndex: 40 }}
      >
        <motion.div
          className="text-center"
          initial="hidden"
          animate="visible"
          variants={titleVariants}
          style={{ zIndex: 30, position: 'relative' }}
        >
          <h1 className="text-5xl md:text-7xl font-extrabold text-black tracking-wide"
           style={{ color: '#1D2D36', fontFamily: 'Montserrat, sans-serif' }} >
            CEYLON SPRINT
          </h1>
          <h2 className="text-2xl md:text-4xl font-medium text-gray-700 mt-2"
           style={{ color: '#1D2D36', fontFamily: 'Montserrat, sans-serif' }}>
            SOFTWARE SOLUTION
          </h2>
        </motion.div>

        <motion.p
          className="text-base md:text-lg text-gray-600 mt-4 font-medium italic relative"
          variants={taglineVariants}
          initial="hidden"
          animate="visible"
          style={{ zIndex: 30, position: 'relative' ,fontFamily: 'Montserrat, sans-serif' }}
        >
          Innovating Your Tomorrow, Today
        </motion.p>

        <motion.div
          className="p-4 mt-4 rounded-xl shadow-lg max-w-full md:max-w-md w-11/12 absolute"
          initial={{ opacity: 0, x: -50, y: 20 }}
          animate={{ opacity: 1, x: 0, y: 20 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{ zIndex: 30, left: '5%', bottom: '10%', position: 'absolute' }}
        >
          <motion.p
            className="text-base md:text-lg font-bold text-left text-gray-800"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            {descriptions[currentDescription]}
          </motion.p>
        </motion.div>
      </header>
    </>
  );
};

export default Header;
