import React from 'react';
import { motion } from 'framer-motion';

const LogoAnimation = () => {
  // const logoAnimation = {
  //   hidden: { opacity: 0, rotate: 0 },
  //   visible: {
  //     opacity: 1,
  //     rotate: [0, 360],
  //     transition: {
  //       duration: 1,
  //       repeat: Infinity,
  //       repeatDelay: 8,
  //     },
  //   },
  // };

  return (
    <motion.div
      className="absolute top-3 left-3 md:top-5 md:left-6 z-30 bg-transperent"
      // variants={logoAnimation}
      initial="hidden"
      animate="visible"
    >
      <img src="../../../asset/light-logo.png" alt="Company logo" className="h-16 md:h-28" />
    </motion.div>
  );
};

export default LogoAnimation;
