import React from 'react';
import Footer from '../components/common/Footer';
import Header from '../components/home/Header/Header';
import Navbar from '../components/common/Navbar';
import ParticlesCanvas from '../components/particlesCanvas';

const MainLayout = ({ children, footerClass }) => {
  return (
    <>
    
      {/* <Header /> */}
      <Navbar />
      <ParticlesCanvas />
      <Header /> 
      <main>{children}</main>
      <Footer className={footerClass} />
    </>
  );
};

export default MainLayout;