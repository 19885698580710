import React from 'react';

const WhoWeAre = () => {
  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-6 md:flex md:gap-12 items-center">
        {/* Left Side: Description */}
        <div className="md:w-1/2 text-center md:text-left">
          <h2 className="text-4xl md:text-5xl font-extrabold text-gray-800 mb-6 leading-snug">
            Who We Are
          </h2>
          <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
            At <span className="font-semibold text-blue-600">Ceylon Sprint Pvt Ltd</span>, we are a dynamic team of tech pioneers dedicated to delivering cutting-edge software solutions. We harness innovation to empower businesses, redefine possibilities, and drive technological advancement.
          </p>
        </div>

        {/* Right Side: Tagline with Background */}
        <div className="relative md:w-1/2 flex justify-center items-center">
          <div className="absolute w-80 h-80 bg-gradient-to-tr from-blue-100 to-purple-200 rounded-full blur-3xl -z-10"></div>
          <div className="bg-gray-100 p-8 rounded-lg shadow-xl relative z-10 text-center">
            <h3 className="text-2xl md:text-4xl font-bold text-gray-800 mb-4">
              CEYLON SPRINT PVT LTD
            </h3>
            <p className="text-lg md:text-2xl font-medium text-gray-600">
              "Your trust is the cornerstone of our success."
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
