import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { submitContactForm } from '../../store/actions/contactActions';

const ContactSellers = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organization: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Telephone is required'),
      organization: Yup.string(),
      message: Yup.string().required('Description is required')
    }),

    onSubmit: values => {
      dispatch(submitContactForm(values))
        .then(() => {
          setIsSubmitted(true);
          formik.resetForm();
          setShowForm(false);
        })
        .catch(error => {
          console.error('Submission Error:', error);
        });
    }
  });

  return (
    <div className="contact-sellers bg-gradient-to-r from-white to-gray-50 py-12 px-6 md:px-12">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-extrabold text-gray-800">Connect with Our Expert Team</h2>
        <button
          onClick={() => setShowForm(!showForm)}
          className={`py-3 px-6 rounded-full font-semibold shadow-md transition-all duration-300 text-lg ${
            showForm ? 'bg-red-500 text-white hover:bg-red-600' : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {showForm ? 'Close Form' : 'Contact Us'}
        </button>
      </div>

      {isSubmitted && (
        <div className="text-green-600 font-semibold text-center mb-4">
          Thank you for reaching out! We will get back to you shortly.
        </div>
      )}

      {showForm && (
        <form
          onSubmit={formik.handleSubmit}
          className="max-w-lg mx-auto bg-white p-8 rounded-xl shadow-lg border border-gray-200"
        >
          <div className="mb-4">
            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="Your Name"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div>
            )}
          </div>

          <div className="mb-4">
            <input
              type="text"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              placeholder="Your Telephone"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
            )}
          </div>

          <div className="mb-4">
            <input
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Your Email"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
            )}
          </div>

          <div className="mb-4">
            <input
              type="text"
              name="organization"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organization}
              placeholder="Your Organization"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {formik.touched.organization && formik.errors.organization && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.organization}</div>
            )}
          </div>

          <div className="mb-6">
            <textarea
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              placeholder="Describe Your Needs"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="5"
            ></textarea>
            {formik.touched.message && formik.errors.message && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 font-semibold"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactSellers;
